// Color
$black: #525656;
$grey: #a0a0a0;
$white: #fff;

$brand: #34bad4;
$brandLight: #f6fcfd;
$brandDark: #208599;

$brandComplement: #d4346a;

$textColor: $black;

// Typography
$bodyFontSize: 1rem;
$ratio: 1.333; // Perfect Fourth

$fontFamilyBody: 'Open Sans', sans-serif;
$fontFamilyHeader: 'Lato', sans-serif;
