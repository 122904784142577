.header {
  display: flex;
  justify-content: flex-end;

  @include box(30px, 20px);
}

.header-image {
  height: 70px;
  margin-right: 7%;

  @include mq(sm) {
    height: 80px;
    margin-right: 12%;
  }

  @include mq(md) {
    margin-right: 18%;
  }
}
