.footer {
  display: flex;
  justify-content: center;
  align-items: center;

  color: rgba($brand, 0.25);
  font-size: scale(-1);

  @include box(0, 20px);

  a {
    color: $brand;
    text-decoration: none;

    padding: 0 10px;
  }
}
