.team {
  position: relative;

  margin: 65px 0 60px;
  padding: 55px 0 30px;

  &::before,
  &::after {
    content: '';

    display: block;
    position: absolute;
    top: 0;
    left: 0;

    z-index: -1;

    min-height: 100%;
    width: 100%;
  }

  &::before {
    top: -20px;
    transform: skew(0, -6deg);
    background: $grey;
    background: linear-gradient(
      40deg,
      rgba($grey, 0.1) 0%,
      rgba($grey, 0.3) 40%,
      rgba($grey, 0.25) 90%,
      rgba($grey, 0.05) 100%
    );
  }

  &::after {
    transform: skew(0, 3deg);

    background: $grey;
    background: linear-gradient(
      40deg,
      rgba($grey, 0.05) 0%,
      rgba($grey, 0.35) 30%,
      rgba($grey, 0.25) 60%,
      rgba($grey, 0.1) 100%
    );
  }
}

.team-content {
  @include box(0, 50px, sm);
}

.team-caption {
  color: $textColor;
  font-size: scale(1);
  line-height: 1;
  letter-spacing: 1px;

  padding-bottom: 12px;
}

.team-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  > a {
    display: flex;
    flex-direction: column;
    align-items: center;

    color: $textColor;
    text-decoration: none;
  }

  img {
    height: 90px;
    width: 90px;

    border-radius: 50%;

    padding: 5px;
  }
}
