h1,
h2,
h3,
h4 {
  margin: 0;
  color: $textColor;
  font-weight: 700;
  font-family: $fontFamilyHeader;
  line-height: 1.2;
  text-transform: uppercase;
}

h1 {
  font-size: scale(4);
}

h2 {
  font-size: scale(3);
}

h3 {
  font-size: scale(2);
}

h4 {
  font-size: scale(1);
}

p {
  margin: 0 0 1rem;

  a {
    color: $brand;
    text-decoration: none;

    border-bottom: 1px solid lighten($brand, 40%);

    &:hover {
      color: lighten($brand, 20%);
    }
  }
}
