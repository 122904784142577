.teaser-container {
  position: relative;
  margin: 30px 0 100px;
  padding: 55px 0 30px;

  @include mq(sm) {
    padding-top: 70px;
  }

  &::before,
  &::after {
    content: '';

    display: block;
    position: absolute;
    top: 0;
    left: 0;

    z-index: -1;

    min-height: 100%;
    width: 100%;
  }

  &::before {
    transform: skew(0, 5deg);
    background: $brandDark;
    background: linear-gradient(
      40deg,
      rgba($brand, 0.5) 0%,
      rgba($brandDark, 0.9) 40%,
      rgba($brandDark, 0.7) 90%,
      rgba($brand, 0.4) 100%
    );
  }

  &::after {
    top: 40px;
    transform: skew(0, -4deg);

    background: $brandDark;
    background: linear-gradient(
      40deg,
      rgba($brand, 0.25) 0%,
      rgba($brandDark, 0.8) 30%,
      rgba($brandDark, 0.75) 60%,
      rgba($brand, 0.8) 100%
    );
  }
}

.teaser {
  color: $white;
  @include box(40px, 40px, sm);
}

.teaser-caption {
  color: rgba($white, 0.75);
  font-family: $fontFamilyHeader;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 1px;

  padding-bottom: 8px;
}

.teaser-title {
  color: $white;
  font-size: scale(4);
  line-height: 1.1;

  border-bottom: 1px solid rgba($white, 0.25);
  padding-bottom: 5px;
}

.teaser-meta {
  display: flex;
  align-items: center;

  color: rgba($white, 0.75);
  font-size: scale(-1);
  line-height: 1;

  padding: 8px 0;

  svg {
    margin-top: -1px;
    margin-right: 4px;
  }

  span + svg {
    margin-left: 10px;
  }
}

.teaser-description {
  padding-top: 4px;

  a,
  a:visited {
    color: $white;
    border-bottom: 1px solid rgba($white, 0.25);
  }
}

.teaser-footer {
  display: flex;
  justify-content: flex-end;

  margin-top: 20px;
}

.teaser-link {
  display: inline-flex;
  align-content: center;

  margin-top: 16px;
  padding: 16px 40px;

  color: $white;
  font-size: scale(0);
  line-height: 1;
  font-family: $fontFamilyHeader;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;

  border-radius: 3px;
  background: $brandComplement;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);

  > svg {
    margin-top: 1px;
    margin-right: 8px;
  }
}
