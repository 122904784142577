// From: http://tobiasahlin.com/spinkit/
.spinner {
  width: 50px;
  height: 50px;

  > div {
    float: left;
    width: 33.3333333%;
    height: 33.3333333%;
    background-color: rgba($white, 0.2);
    -webkit-animation: spinner-animation 1.3s infinite ease-in-out;
    animation: spinner-animation 1.3s infinite ease-in-out;

    &:nth-child(1) {
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s;
    }
    &:nth-child(2) {
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
    }
    &:nth-child(3) {
      -webkit-animation-delay: 0.4s;
      animation-delay: 0.4s;
    }
    &:nth-child(4) {
      -webkit-animation-delay: 0.1s;
      animation-delay: 0.1s;
    }
    &:nth-child(5) {
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s;
    }
    &:nth-child(6) {
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
    }
    &:nth-child(7) {
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
    }
    &:nth-child(8) {
      -webkit-animation-delay: 0.1s;
      animation-delay: 0.1s;
    }
    &:nth-child(9) {
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s;
    }
  }
}

@-webkit-keyframes spinner-animation {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes spinner-animation {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}
